import arkon_environment from "../env";

/*const ENVIRONMENT = "dev";
let _apiUrl;
let _websiteUrl;

switch (ENVIRONMENT) {
  case "dev":
    _apiUrl = "http://localhost:3001";
    _websiteUrl = "http://localhost:3000";
    break;
  case "test":
    _apiUrl = "http://localhost:3001";
    _websiteUrl = "http://localhost:3000";

    //_apiUrl = "http://44.198.178.125";
    //_websiteUrl = "http://44.198.178.125";

    //_apiUrl = "http://192.168.11.144";
    //_websiteUrl = "http://192.168.11.144";
    break;
  case "prod":
    // IP LOCAL
    _apiUrl = "http://10.0.1.8";
    _websiteUrl = "http://10.0.1.8";

    // IP PÚBLICA
    _apiUrl = "http://44.198.178.125";
    _websiteUrl = "http://44.198.178.125";

    break;
  default:
    _apiUrl = "http://localhost:3001";
    _websiteUrl = "http://localhost:3000";
    break;
}
*/

const appConfig = {
  apiPrefix: "/api",
  apiUrl: arkon_environment.API_URL,
  websiteUrl: arkon_environment.WEBSITE_URL,
  authenticatedEntryPath: "/core/prin/inic-dash/dashboard/",
  unAuthenticatedEntryPath: "/core/prin/inic-sesi/iniciar-sesion/",
  tourPath: "/",
  enableMock: false,
};

export default appConfig;
