import React, { useRef, createContext, useContext, useReducer } from "react";

import ApiService from "services/ApiService";
import ReactFileDownload from "react-file-download";

import { toast, Notification } from "components/ui";
import { CoreGuardar } from "services/dlorean/CoreGuardar";
import { CoreGuardarExterno } from "services/dlorean/CoreGuardar";
import { useNavigate } from "react-router-dom";

const DloreanStoreContext = createContext();
const useDlorean = () => useContext(DloreanStoreContext);
const initialState = {
  // MODULO ACTUAL
  sk_proyecto: "",
  sk_subproyecto: "",
  sk_modulo: "",
  s_slug: "",
  s_url: "",
  sNombreModulo: "",
  PUBLIC: false,
  paramsUrl: "",
  p1: null,
  p2: null,
  p3: null,
  p4: null,
  p5: null,

  // MODULO ANTERIOR
  skProyectoAnterior: "",
  skSubproyectoAnterior: "",
  skModuloAnterior: "",
  sSlugAnterior: "",
  sUrlAnterior: "",
  sNombreModuloAnterior: "",
  PUBLICAnterior: false,
  paramsUrlAnterior: "",
  p1Anterior: null,
  p2Anterior: null,
  p3Anterior: null,
  p4Anterior: null,
  p5Anterior: null,

  // CONFIGURACIÓN DEL BOTÓN GUARDAR
  configuracion_boton_guardar: {},

  // DATOS_USUARIO //
  DATOS_USUARIO: false,

  // CARGAR MÓDULO
  CARGAR_MODULO: null,

  FUNCIONES_CORE: null,

  // FUNCIONES DEL CORE
  FUNCIONES_BOTONES: {
    nuevo: null,
    guardar: null,
    consulta: null,
    pdf: null,
    excel: null,
  },

  CARGANDO_BOTON_GUARDAR: false,

  // FUNCIONES MENU EMERGENTE
  FUNCIONES_MENU_EMERGENTE: {
    CARGAR_MODULO: null,
  },

  // FULL COMPONENT //
  RELOAD_SHOW: false,
  RELOAD_LOADED: false,
  RELOAD_NOMBRE_MODULO: "",
  FORMULARIO_REF: null,
  RELOAD_BREADCRUMB: [],
  RELOAD_BUTTONS: [],

  // DIALOG //
  DIALOG_SHOW: false,
  DIALOG_LOADED: false,
  DIALOG_NOMBRE_MODULO: "",
  DIALOG_BREADCRUMB: [],
  DIALOG_BUTTONS: [],

  // DRAWER //
  DRAWER_SHOW: false,
  DRAWER_LOADED: false,
  DRAWER_NOMBRE_MODULO: "",
  DRAWER_BREADCRUMB: [],
  DRAWER_BUTTONS: [],

  // CONFIRM DIALOG //
  CONFIRM_DIALOG: false,

  // RELOAD TABLE (TABLA DE CONSULTA) //
  RELOAD_TABLE: false,

  // CONTEXTO DE MÓDULO //
  CONTEXTO_MODULO: {
    /*"core-prin-hica-inde": {
      dataTable: { pageIndex: 2, filterData: { col: "modulo", val: "Consultar Empresas" } },
    },*/
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    // MODULO ACTUAL
    case "CURRENT_MODULE":
      return {
        ...state,

        sk_proyecto: action.payload.sk_proyecto,
        sk_subproyecto: action.payload.sk_subproyecto,
        sk_modulo: action.payload.sk_modulo,
        s_slug: action.payload.s_slug,
        s_url: action.payload.s_url,
        sNombreModulo: action.payload.sNombreModulo,
        PUBLIC: action.payload.PUBLIC,
        paramsUrl: action.payload.paramsUrl,
        p1: action.payload.p1,
        p2: action.payload.p2,
        p3: action.payload.p3,
        p4: action.payload.p4,
        p5: action.payload.p5,

        skProyectoAnterior: state.sk_proyecto,
        skSubproyectoAnterior: state.sk_subproyecto,
        skModuloAnterior: state.sk_modulo,
        sSlugAnterior: state.s_slug,
        sUrlAnterior: state.s_url,
        sNombreModuloAnterior: state.sNombreModulo,
        PUBLICAnterior: state.PUBLIC,
        paramsUrlAnterior: state.paramsUrl,
        p1Anterior: state.p1,
        p2Anterior: state.p2,
        p3Anterior: state.p3,
        p4Anterior: state.p4,
        p5Anterior: state.p5,
      };

    // MODULO ANTERIOR
    case "PREVIOUS_MODULE":
      return {
        ...state,

        sk_proyecto: state.skProyectoAnterior,
        sk_subproyecto: state.skSubproyectoAnterior,
        sk_modulo: state.skModuloAnterior,
        s_slug: state.sSlugAnterior,
        s_url: state.sUrlAnterior,
        sNombreModulo: state.sNombreModuloAnterior,
        PUBLIC: state.PUBLICAnterior,
        paramsUrl: state.paramsUrlAnterior,
        p1: state.p1Anterior,
        p2: state.p2Anterior,
        p3: state.p3Anterior,
        p4: state.p4Anterior,
        p5: state.p5Anterior,

        skProyectoAnterior: state.sk_proyecto,
        skSubproyectoAnterior: state.sk_subproyecto,
        skModuloAnterior: state.sk_modulo,
        sSlugAnterior: state.s_slug,
        sUrlAnterior: state.s_url,
        sNombreModuloAnterior: state.sNombreModulo,
        PUBLICAnterior: state.PUBLIC,
        paramsUrlAnterior: state.paramsUrl,
        p1Anterior: state.p1,
        p2Anterior: state.p2,
        p3Anterior: state.p3,
        p4Anterior: state.p4,
        p5Anterior: state.p5,
      };

    // CARGAR MÓDULO
    case "CARGAR_MODULO":
      return { ...state, CARGAR_MODULO: action.payload.CARGAR_MODULO };

    case "FUNCIONES_CORE":
      const FUNCIONES_CORE = { ...state.FUNCIONES_CORE, ...action.payload };
      return { ...state, FUNCIONES_CORE: FUNCIONES_CORE };

    case "FUNCIONES_MENU_EMERGENTE":
      return { ...state, FUNCIONES_MENU_EMERGENTE: action.payload };

    case "LIMPIAR_FUNCIONES_BOTONES":
      return {
        ...state,
        FUNCIONES_BOTONES: {
          nuevo: state.FUNCIONES_BOTONES.nuevo,
          guardar: state.FUNCIONES_BOTONES.guardar,
          coreGuardar: state.FUNCIONES_BOTONES.coreGuardar,
          coreGuardarExterno: state.FUNCIONES_BOTONES.coreGuardarExterno,
          consulta: state.FUNCIONES_BOTONES.consulta,
          pdf: state.FUNCIONES_BOTONES.pdf,
          excel: state.FUNCIONES_BOTONES.excel,
        },
      };

    // AGREGAR FUNCION MENU EMERGENTE PERSONALIZADA
    case "AGREGAR_FUNCION_MENU_EMERGENTE":
      const funcionesPersonalizadasME = {};
      for (let key in action.payload) {
        const callback = key;
        funcionesPersonalizadasME[callback] = action.payload[key];
      }
      const funcionesCallbackME = { ...state.FUNCIONES_MENU_EMERGENTE, ...funcionesPersonalizadasME };
      return { ...state, FUNCIONES_MENU_EMERGENTE: funcionesCallbackME };

    // AGREGAR FUNCION BOTON PERSONALIZADA
    case "AGREGAR_FUNCION_BOTON":
      const funcionesPersonalizadas = {};
      for (let key in action.payload) {
        const functionName = `${state.sk_proyecto}_${state.sk_subproyecto}_${state.sk_modulo}_${key}`;
        const callback = functionName.replace("-", "_");
        funcionesPersonalizadas[callback] = action.payload[key];
      }
      const funcionesCallback = { ...state.FUNCIONES_BOTONES, ...funcionesPersonalizadas };
      return { ...state, FUNCIONES_BOTONES: funcionesCallback };
    case "CARGANDO_BOTON_GUARDAR":
      return { ...state, CARGANDO_BOTON_GUARDAR: action.payload.CARGANDO_BOTON_GUARDAR };
    // RELOAD COMPONENT
    case "RELOAD_SHOW":
      return { ...state, RELOAD_SHOW: action.payload.RELOAD_SHOW };
    case "RELOAD_CONFIG":
      return { ...state, RELOAD_NOMBRE_MODULO: action.payload.RELOAD_NOMBRE_MODULO };
    case "RELOAD_BREADCRUMB":
      return { ...state, RELOAD_BREADCRUMB: action.payload.RELOAD_BREADCRUMB };
    case "RELOAD_BUTTONS":
      return { ...state, RELOAD_BUTTONS: action.payload.RELOAD_BUTTONS };
    case "RELOAD_LOADED":
      return { ...state, RELOAD_LOADED: action.payload.RELOAD_LOADED };

    // DIALOG //
    case "DIALOG_SHOW":
      return { ...state, DIALOG_SHOW: action.payload.DIALOG_SHOW };
    case "DIALOG_CONFIG":
      return { ...state, DIALOG_NOMBRE_MODULO: action.payload.DIALOG_NOMBRE_MODULO };
    case "DIALOG_BREADCRUMB":
      return { ...state, DIALOG_BREADCRUMB: action.payload.DIALOG_BREADCRUMB };
    case "DIALOG_BUTTONS":
      return { ...state, DIALOG_BUTTONS: action.payload.DIALOG_BUTTONS };
    case "DIALOG_LOADED":
      return { ...state, DIALOG_LOADED: action.payload.DIALOG_LOADED };

    // DRAWER //
    case "DRAWER_SHOW":
      return { ...state, DRAWER_SHOW: action.payload.DRAWER_SHOW };
    case "DRAWER_CONFIG":
      return { ...state, DRAWER_NOMBRE_MODULO: action.payload.DRAWER_NOMBRE_MODULO };
    case "DRAWER_BREADCRUMB":
      return { ...state, DRAWER_BREADCRUMB: action.payload.DRAWER_BREADCRUMB };
    case "DRAWER_BUTTONS":
      return { ...state, DRAWER_BUTTONS: action.payload.DRAWER_BUTTONS };
    case "DRAWER_LOADED":
      return { ...state, DRAWER_LOADED: action.payload.DRAWER_LOADED };

    case "CONFIRM_DIALOG":
      const CONFIRM_DIALOG = { ...action.payload, show: action.payload.show === false ? false : true };
      return { ...state, CONFIRM_DIALOG };

    case "RELOAD_TABLE":
      return { ...state, RELOAD_TABLE: action.payload };

    case "DATOS_USUARIO":
      return { ...state, DATOS_USUARIO: action.payload };

    case "CONTEXTO_MODULO":
      const CONTEXTO_MODULO = { ...state.CONTEXTO_MODULO };
      const modulo = CONTEXTO_MODULO[action.payload.key]
        ? { [action.payload.key]: CONTEXTO_MODULO[action.payload.key] }
        : { [action.payload.key]: {} };
      //console.log("modulo", modulo);
      //console.log("payload", action.payload.values);
      for (let value in action.payload.values) {
        //console.log("value", action.payload.values[value]);
        modulo[action.payload.key][value] = action.payload.values[value];
      }
      //console.log("FINAL", modulo);
      const NEW_CONTEXTO_MODULO = { ...state.CONTEXTO_MODULO, ...modulo };
      //console.log("CONTEXTO_MODULO", NEW_CONTEXTO_MODULO);
      return { ...state, CONTEXTO_MODULO: NEW_CONTEXTO_MODULO };
    default:
      return state;
  }
};

const DloreanStoreProvider = ({ children }) => {
  const navigate = useNavigate();

  // CREAMOS LA REFERENCIA PARA LOS FORMULARIOS
  initialState.FORMULARIO_REF = useRef(null);

  // AGREGAMOS LAS FUNCIONES CORE DE BOTONES
  initialState.FUNCIONES_BOTONES.nuevo = async (conf) => {
    const s_url = `/${conf.sk_proyecto_destino}/${conf.sk_subproyecto_destino}/${conf.sk_modulo_destino}/${conf.s_slug_destino}/`;
    if (conf.newWindow) {
      window.open(`${s_url}`, "_blank");
    } else {
      navigate(s_url);
    }
  };

  initialState.FUNCIONES_BOTONES.guardar = (conf) => {
    initialState.configuracion_boton_guardar = conf;
    initialState.FORMULARIO_REF.current.submitForm();
    initialState.CARGANDO_BOTON_GUARDAR = false;
  };

  /* 
    ---- EJEMPLO CON coreGuardar Redireccionado Personalizado.
    const response = await coreDlorean.FUNCIONES_BOTONES.coreGuardar(dataFinal, setSubmitting,
        {
                sk_proyecto_destino: 'agen',
                sk_subproyecto_destino: 'traf',
                sk_modulo_destino: 'clas-inde',
                s_slug_destino: 'clasificacion-arancelaria'
            }
        );
  
  */
  initialState.FUNCIONES_BOTONES.coreGuardar = async (
    values,
    setSubmitting,
    navigateUrl = true,
    configuracion_boton_custom = false
  ) => {
    try {
      const { configuracion_boton_guardar } = initialState;
      setSubmitting(true);
      //Se llama la función de la API
      const response = await CoreGuardar(
        values,
        configuracion_boton_custom ? configuracion_boton_custom : configuracion_boton_guardar
      );

      setSubmitting(false);

      if (response.data.success) {
        toast.push(
          <Notification title={"Notificación"} type="success" duration={2500} closable>
            {response.data.message}
          </Notification>,
          {
            placement: "top-center",
          }
        );

        if (navigateUrl === true && configuracion_boton_custom === false) {
          const s_url = `/${configuracion_boton_guardar.sk_proyecto_destino}/${configuracion_boton_guardar.sk_subproyecto_destino}/${configuracion_boton_guardar.sk_modulo_destino}/${configuracion_boton_guardar.s_slug_destino}/`;
          navigate(s_url);
        } else if (navigateUrl === true && typeof configuracion_boton_custom === "object") {
          const s_url = `/${configuracion_boton_custom.sk_proyecto_destino}/${configuracion_boton_custom.sk_subproyecto_destino}/${configuracion_boton_custom.sk_modulo_destino}/${configuracion_boton_custom.s_slug_destino}/`;
          navigate(s_url);
        }

        return response;
      }
    } catch (e) {
      if (e?.response?.data?.errors?.EXPRESS_VALIDATOR) {
        const message = await e?.response?.data?.errors?.EXPRESS_VALIDATOR.map((error) => {
          return <p>{error.msg}</p>;
        });
        console.log("message", message.join(""));
        toast.push(
          <Notification title={"NOTIFICACIÓN"} type="danger" duration={5000}>
            <>
              <p className="font-bold">{"Validación de Formulario"}</p>
              {message}
            </>
          </Notification>,
          {
            placement: "top-center",
          }
        );
      }
      if (e?.response?.data?.errors?.SequelizeValidationError || e?.response?.data?.errors?.SequelizeDatabaseError) {
        console.log("Error", e);

        const error_name = e?.response?.data?.errors?.SequelizeValidationError?.name
          ? e?.response?.data?.errors?.SequelizeValidationError?.name
          : e?.response?.data?.errors?.SequelizeDatabaseError?.name;

        const error_description = e?.response?.data?.errors?.SequelizeValidationError?.description
          ? e?.response?.data?.errors?.SequelizeValidationError?.description
          : e?.response?.data?.errors?.SequelizeDatabaseError?.description;

        toast.push(
          <Notification title={"NOTIFICACIÓN"} type="danger" duration={5000}>
            <>
              <p className="font-bold">{error_name}</p>
              <p>{error_description}</p>
            </>
          </Notification>,
          {
            placement: "top-center",
          }
        );
      }
      return false;
    }
  };

  initialState.FUNCIONES_BOTONES.consulta = (conf) => {
    const s_url = `${conf.sk_proyecto_destino}/${conf.sk_subproyecto_destino}/${conf.sk_modulo_destino}/${conf.s_slug_destino}/`;
    navigate(s_url);
  };

  initialState.FUNCIONES_BOTONES.pdf = async (conf) => {
    //console.log("BOTON PDF", conf);
    let data = {};
    if (conf.coreDlorean.CONTEXTO_MODULO[`${conf.sk_proyecto}-${conf.sk_subproyecto}-${conf.sk_modulo}`]) {
      data =
        conf.coreDlorean.CONTEXTO_MODULO[`${conf.sk_proyecto}-${conf.sk_subproyecto}-${conf.sk_modulo}`]["dataTable"];
    }
    return await ApiService.fetchData({
      url: `/${conf.sk_proyecto_destino}/${conf.sk_subproyecto_destino}/${conf.sk_modulo_destino}/${conf.s_slug_destino}/generarPDF/`,
      method: "post",
      //responseType: "arraybuffer",
      responseType: "blob",
      data: data,
    }).then((response) => {
      const contentDispositionHeader = response.headers["content-disposition"];
      const match = contentDispositionHeader.match(/filename="?(.+)"?/);
      const filename = match ? match[1] : "Portal.pdf";

      const pdfUrl = URL.createObjectURL(response.data);
      const myWindow = window.open(pdfUrl, "_blank");
      //var myWindow = window.open('<myfile>.pdf','my window','resizable,scrollbars');
      //myWindow.document.write("<title>My PDF File Title</title>");
      //URL.revokeObjectURL(pdfUrl);

      //ReactFileDownload(response.data, filename);
    });
  };

  initialState.FUNCIONES_BOTONES.excel = async (conf) => {
    //console.log("BOTON EXCEL", conf);
    let data = {};
    if (conf.coreDlorean.CONTEXTO_MODULO[`${conf.sk_proyecto}-${conf.sk_subproyecto}-${conf.sk_modulo}`]) {
      data =
        conf.coreDlorean.CONTEXTO_MODULO[`${conf.sk_proyecto}-${conf.sk_subproyecto}-${conf.sk_modulo}`]["dataTable"];
    }
    return await ApiService.fetchData({
      url: `/${conf.sk_proyecto_destino}/${conf.sk_subproyecto_destino}/${conf.sk_modulo_destino}/${conf.s_slug_destino}/generarExcel/`,
      method: "post",
      responseType: "arraybuffer",
      data,
    }).then((response) => {
      const contentDispositionHeader = response.headers["content-disposition"];
      const match = contentDispositionHeader.match(/filename="?(.+)"?/);
      const filename = match ? match[1] : "Portal.xlsx";

      ReactFileDownload(response.data, filename);
    });
  };

  const [coreDlorean, dispatchDlorean] = useReducer(reducer, initialState);

  return (
    <DloreanStoreContext.Provider value={{ coreDlorean, dispatchDlorean }}>{children}</DloreanStoreContext.Provider>
  );
};

export { DloreanStoreContext, DloreanStoreProvider, useDlorean };
