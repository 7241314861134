import React from "react";
//import authRoute from "./authRoute";

/* 
    AQUI SE ESTAN MOSTRANDO LAS RUTAS PUBLICAS Y LAS PRIVADAS
    LO QUE VAMOS A TENER QUE HACER ES A LA HORA DE INICIAR SESIÓN SE TOMARÁ DEL ESTADO O DEL LOCALSTORAGE TODAS LAS RUTAS
    PUBLICAS O PRIVADAS
*/

//export const publicRoutes = [...authRoute];

export const misRutas = [
  {
    key: "agen-traf-glos-docu",
    path: "/:sk_proyecto?/:sk_subproyecto?/:sk_modulo?/:s_slug?/:p1?/:p2?/:p3?/:p4?/:p5?/",
    component: React.lazy(() => import("views/DLOREAN_TEMPLATE/DloreanLayout")),
    authority: [],
  },
  {
    key: "rutas-dinamicas",
    path: "/:sk_proyecto?/:sk_subproyecto?/:sk_modulo?/:s_slug?/:p1?/:p2?/:p3?/:p4?/:p5?/",
    component: React.lazy(() => import("views/DLOREAN_TEMPLATE/DloreanLayout")),
    authority: [],
  },
];
