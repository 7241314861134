export default function Util_FormatearNumero(monto, digitos = 2) {
    const montoSinCeros = String(monto).replace(/^0+/, '');

    // Parsear la cadena a un número decimal
    const montoDecimal = parseFloat(montoSinCeros);
  
    // Dar formato al número con dos decimales y coma para separar miles
    const montoFormateado = montoDecimal.toLocaleString('en-US', {
      minimumFractionDigits: digitos,
      maximumFractionDigits: digitos,
    });
  
    return montoFormateado;
}