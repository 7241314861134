import React, { forwardRef, useMemo, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table, Pagination, Select, Checkbox, Tooltip } from "components/ui";
import TableRowSkeleton from "./loaders/TableRowSkeleton";
import Loading from "./Loading";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { useDlorean } from "store/DLOREAN/dloreanStore";
import { Menu, Item, Separator, Submenu, useContextMenu } from "react-contexify";
import useThemeClass from 'utils/hooks/useThemeClass'
import "react-contexify/dist/ReactContexify.css";
import useDarkMode from "utils/hooks/useDarkMode";
import Util_FormatearNumero from "utils/dlorean/Util_FormatearNumero";
const MENU_ID = "menu-id";

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const { indeterminate, onChange, onCheckBoxChange, onIndeterminateCheckBoxChange, ...rest } = props;

  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChange = (e) => {
    onChange(e);
    onCheckBoxChange?.(e);
    onIndeterminateCheckBoxChange?.(e);
  };

  return <Checkbox className="mb-0" ref={resolvedRef} onChange={(_, e) => handleChange(e)} {...rest} />;
});

const handlerMenuEmergente = (e, coreDlorean, conf, data) => {
  try {
    let newWindow = false;
    if (e.event.ctrlKey) {
      newWindow = true;
    }
    const obj = { ...data, menuEmergente: conf, newWindow };
    coreDlorean.FUNCIONES_MENU_EMERGENTE[conf.s_funcion](obj);
  } catch (error) {
    console.error(`LA FUNCIÓN [${conf.s_funcion}] NO ESTÁ DEFINIDA`);
  }
};


const renderMenuItems = (items, coreDlorean) => {
  const { menuEmergente, ...data } = items;
  if (!items?.menuEmergente) {
    return;
  }
  return items.menuEmergente.map((item) => {

    if (item.submenu) {
      return (
        <Submenu key={item.id} label={item.label}>
          {renderSubMenuItems(item.submenu, coreDlorean, data)}
        </Submenu>
      );
    } else if (item.disabled) {
      return (
        <Item key={item.id} disabled>
          {item.label}
        </Item>
      );
    } else {
      return (
        <Item key={item.id} onClick={(e) => handlerMenuEmergente(e, coreDlorean, item, data)}>
          {item.label}
        </Item>
      );
    }
  });
};

const renderSubMenuItems = (itemsSubMenu, coreDlorean, data) => {
  return itemsSubMenu.map((item) => {
    if (item.disabled) {
      return (
        <Item key={item.id} disabled>
          {item.label}
        </Item>
      );
    } else {
      return (
        <Item key={item.id} onClick={(e) => handlerMenuEmergente(e, coreDlorean, item, data)}>
          {item.label}
        </Item>
      );
    }
  });
};

const DataTable = (props) => {
  const [menuEmergente, setMenuEmergente] = useState({});
  const [loadMenuEmergente, setLoadMenuEmergente] = useState(false);
  const [cellData, set_cellData] = useState();
  const { textTheme, bgTheme, borderTheme, ringTheme } = useThemeClass()
  const { show } = useContextMenu({
    id: MENU_ID,
  });
  const {
    skeletonAvatarColumns,
    columns,
    data,
    loading,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onPaginationChange,
    onSelectChange,
    onSort,
    pageSizes,
    selectable,
    skeletonAvatarProps,
    pagingData,
    autoResetSelectedRows,
  } = props;

  const { coreDlorean, dispatchDlorean } = useDlorean();

  const { pageSize, pageIndex, total } = pagingData;

  const pageSizeOption = useMemo(
    () => pageSizes.map((number) => ({ value: number, label: `${number}` })),
    [pageSizes]
  );

  const handleCheckBoxChange = (checked, row) => {
    if (!loading) {
      onCheckBoxChange?.(checked, row);
    }
  };

  const handleIndeterminateCheckBoxChange = (checked, rows) => {
    if (!loading) {
      onIndeterminateCheckBoxChange?.(checked, rows);
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetSelectedRows,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: (props) => (
              <div>
                <IndeterminateCheckbox
                  {...props.getToggleAllRowsSelectedProps()}
                  onIndeterminateCheckBoxChange={(e) => handleIndeterminateCheckBoxChange(e.target.checked, props.rows)}
                />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onCheckBoxChange={(e) => handleCheckBoxChange(e.target.checked, row.original)}
                />
              </div>
            ),
            sortable: false,
          },
          ...columns,
        ]);
      }
    }
  );

  const handlePaginationChange = (page) => {
    if (!loading) {
      onPaginationChange?.(page);
    }
  };

  const handleSelectChange = (value) => {
    if (!loading) {
      onSelectChange?.(Number(value));
    }
  };

  const handleSort = (column) => {
    if (!loading) {
      const { id, isSortedDesc, toggleSortBy, clearSortBy } = column;
      const sortOrder = isSortedDesc ? "desc" : "asc";
      toggleSortBy(!isSortedDesc);
      onSort?.({ order: sortOrder, key: id }, { id, clearSortBy });
    }
  };

  const updateMenu = async (menu) => {
    setMenuEmergente(menu);
    setLoadMenuEmergente(true);
  };

  const displayMenu = async (e, menu) => {
    if (!menu?.menuEmergente) {
      return;
    }
    await updateMenu(menu);
    show({
      event: e,
    });
  };

  const [isDark, setIsDark] = useDarkMode();

  const wrapTextStyles = {
    whiteSpace: 'nowrap', // Evita el salto de línea
    overflow: 'hidden',   // Oculta el contenido que excede el ancho de la celda
    textOverflow: 'ellipsis', // Agrega puntos suspensivos (...) cuando el texto es demasiado largo
  };

  function isIOS() {
    const userAgent = navigator.userAgent;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  }

  return (
    <Loading loading={loading && data.length !== 0} type="cover">

      <Table hoverable={false} compact={true} {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} className={'bg-gray-50 dark:bg-gray-700'}>
              {headerGroup.headers.map((column) => (
                <Th style={wrapTextStyles} {...column.getHeaderProps()}>
                  {column.render("Header") &&
                    (column.sortable ? (
                      <div className="cursor-pointer" onClick={() => handleSort(column)}>
                        {column.render("Header") &&
                          (column.tooltip ? (
                            <Tooltip title={column.tooltip}> {column.render("Header")}</Tooltip>
                          ) : (
                            <>{column.render("Header")}</>
                          ))}
                        <span>
                          <Sorter sort={column.isSortedDesc} />
                        </span>
                      </div>
                    ) : (
                      <div>{column.render("Header")}</div>
                    ))}
                </Th>
              ))}
            </Tr>
          ))}
        </THead>
        {loading && data.length === 0 ? (
          <TableRowSkeleton
            columns={columns.length}
            //rows={data.pageSize}
            rows={1}
          //avatarInColumns={skeletonAvatarColumns}
          //avatarProps={skeletonAvatarProps}
          />
        ) : (
          <TBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);

              return (
                <Tr
                  className="hover:bg-gray-100 hover:dark:bg-gray-600 cursor-context-menu"
                  {...row.getRowProps()}
                  onContextMenu={(event) => {
                    event.preventDefault();
                    displayMenu(event, row.original);
                  }}
                  onClick={(event) => {
                    if (isIOS()) {
                      event.preventDefault();
                      displayMenu(event, row.original);
                    }
                  }}
                >
                  {/* Aquí es donde se marcara el campo principal de la tabla */}
                  {row.cells.map((cell) => {
                    if (cell.column.bgColor) {
                      return <Td style={wrapTextStyles} className={`${typeof cell.column.bgColor === 'string' ? cell.column.bgColor : `${bgTheme}`} bg-opacity-90 rounded-sm text-white`} {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
                    } else {
                      return <Td style={wrapTextStyles} {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
                    }

                  })}
                </Tr>
              );
            })}
          </TBody>
        )}
      </Table>

      <div className="flex flex-col md:flex-row items-center justify-between mt-4">
        <Pagination pageSize={pageSize} currentPage={pageIndex} total={total} onChange={handlePaginationChange} />
        <div style={{ minWidth: 60 }} className="flex items-center my-2 md:mt-0">
          <span>Mostrar </span>
          <Select
            className="mx-2"
            isClearable={false}
            size="xs"
            menuPlacement="top"
            isSearchable={false}
            value={pageSizeOption.filter((option) => option.value === pageSize)}
            options={pageSizeOption}
            onChange={(option) => handleSelectChange(option.value)}
          />
          <span className="mr-3">de {props.totalData ? Util_FormatearNumero(props.totalData, 0) : 0} </span>

        </div>
      </div>

      {loadMenuEmergente && menuEmergente && (
        <Menu id={MENU_ID} theme={isDark ? "dark" : "light"}>
          {renderMenuItems(menuEmergente, coreDlorean)}
        </Menu>
      )}
    </Loading>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  onIndeterminateCheckBoxChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSort: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
  skeletonAvatarProps: PropTypes.object,
  pagingData: PropTypes.shape({
    total: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  autoResetSelectedRows: PropTypes.bool,
};

DataTable.defaultProps = {
  pageSizes: [15, 25, 50, 100],
  pagingData: {
    total: 0,
    pageIndex: 1,
    pageSize: 15,
  },
  data: [],
  columns: [],
  selectable: false,
  loading: false,
  autoResetSelectedRows: true,
};

export default DataTable;
