import { createContext, useState, useContext, useEffect } from "react";
import ReactFileDownload from "react-file-download";
import { api_obtenerImagen } from "services/AuthService";
export const AppContext = createContext();
export const useMyContext = () => useContext(AppContext);

const ProviderContext = ({ children }) => {
  const [state, setState] = useState({});
  const cargarFotoPerfil = async () => {
    const datos = JSON.parse(localStorage.getItem("datos"));
    if (datos) {
      const resApiImg = await api_obtenerImagen({ sk_usuario: datos.usuario.sk_usuario });
      const resImg = resApiImg.data.data;
      let blob_imagen_perfil = false;
      if (resImg.img !== "") {
        const img = new Uint8Array(resImg.img.data);
        const blob = new Blob([img], { type: resImg.s_content_type });
        blob_imagen_perfil = URL.createObjectURL(blob);
      }

      const prevDatos = {
        ...datos,
        blob_s_foto: blob_imagen_perfil || undefined,
      };
      localStorage.setItem("datos", JSON.stringify(prevDatos));
      setState(prevDatos);
    }
  };
  useEffect(() => {
    if (state) {
      //setState(JSON.parse(localStorage.getItem('datos')));
      cargarFotoPerfil();
    }
  }, [children]);

  return <AppContext.Provider value={[state, setState]}>{children}</AppContext.Provider>;
};

export default ProviderContext;
