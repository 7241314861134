import ApiService from "./ApiService";
import appConfig from "configs/app.config";
import axios from "axios";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/core/prin/inic-sesi/iniciar-sesion/iniciarSesion/",
    method: "post",
    data,
  });
}

export async function apiConfigurarInicio(data) {
  return ApiService.fetchData({
    url: "/core/prin/inic-sesi/iniciar-sesion/configurarInicio/",
    method: "post",
    data,
  });
}

export async function api_obtenerImagen(params) {
  try {
   return await ApiService.fetchData({
      url: "/core/prin/inic-sesi/iniciar-sesion/obtenerImagenPerfil/",
      method: "get",
      params: params
    });
  } catch (error) {
    console.error(error);
  }
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/sign-up",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/sign-out",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/core/prin/inic-recu/recuperar-contra/recuperarPassword/",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/core/prin/inic-rese/reset-password/resetPassword/",
    method: "post",
    data,
  });
}
