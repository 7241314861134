import React, { memo, useMemo, lazy, Suspense } from "react";
import { Loading } from "components/shared";
import { useSelector } from "react-redux";
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from "constants/theme.constant";
import useAuth from "utils/hooks/useAuth";
import useDirection from "utils/hooks/useDirection";
import useLocale from "utils/hooks/useLocale";
import { DloreanStoreProvider } from "store/DLOREAN/dloreanStore";
import { misRutas } from "configs/routes.config";

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import("./ClassicLayout")),
  [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import("./StackedSideLayout")),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import("./SimpleLayout")),
  [LAYOUT_TYPE_DECKED]: lazy(() => import("./DeckedLayout")),
  [LAYOUT_TYPE_BLANK]: lazy(() => import("./BlankLayout")),
};

const Layout = () => {
  const layoutType = useSelector((state) => state.theme.layout.type);

  const { authenticated } = useAuth();

  useDirection();

  useLocale();

  const AppLayout = useMemo(() => {
    const pathname = window.location.pathname.split("/");
    const key_route = `${pathname[1]}-${pathname[2]}-${pathname[3]}`;
    const route_layout_type_blank = misRutas.filter((ruta) => ruta.key === key_route);
    if (route_layout_type_blank.length > 0) {
      return layouts[LAYOUT_TYPE_BLANK];
    }
    if (authenticated) {
      return layouts[layoutType];
    }
    return lazy(() => import("./AuthLayout"));
  }, [layoutType, authenticated]);

  return (
    <DloreanStoreProvider>
      <Suspense
        fallback={
          <div className="flex flex-auto flex-col h-[100vh]">
            <Loading loading={true} />
          </div>
        }
      >
        <AppLayout />
      </Suspense>
    </DloreanStoreProvider>
  );
};

export default memo(Layout);
