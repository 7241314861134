import React from "react";
import { Dropdown, Button } from "components/ui";
import * as In from "react-icons/fa";
import { useDlorean } from "store/DLOREAN/dloreanStore";
import { HiOutlineViewGridAdd } from "react-icons/hi";

export default function DloreanButtonsReload({ isMobile = false }) {
  const { coreDlorean, dispatchDlorean } = useDlorean();
  const Icono = (tipo) => {
    switch (tipo) {
      case "GUAR":
        return <In.FaRegSave></In.FaRegSave>;
      case "PDFS":
        return <In.FaRegFilePdf></In.FaRegFilePdf>;
      case "EXCE":
        return <In.FaRegFileExcel></In.FaRegFileExcel>;
      case "NUEV":
        return <In.FaRegShareSquare></In.FaRegShareSquare>;
      default:
        return <In.FaRegWindowMinimize></In.FaRegWindowMinimize>;
    }
  };

  const ejecutar_funcion_boton = (e, conf) => {
    try {
      let newWindow = false;
      if (e.ctrlKey) {
        newWindow = true;
      }

      if (conf.sk_boton === "GUAR") {
        dispatchDlorean({ type: "CARGANDO_BOTON_GUARDAR", payload: { CARGANDO_BOTON_GUARDAR: true } });

        const timer = setInterval(() => {
          dispatchDlorean({ type: "CARGANDO_BOTON_GUARDAR", payload: { CARGANDO_BOTON_GUARDAR: false } });
          clearInterval(timer);
        }, 3000);



      }

      const functionName = `${conf.sk_proyecto}_${conf.sk_subproyecto}_${conf.sk_modulo}_${conf.s_funcion}`;
      const callback = functionName.replace("-", "_");
      conf.paramsUrl = coreDlorean.paramsUrl || "";
      const args = { ...conf, coreDlorean: coreDlorean, newWindow };
      if (coreDlorean.FUNCIONES_BOTONES.hasOwnProperty(callback)) {
        coreDlorean.FUNCIONES_BOTONES[callback](args);
      } else {
        coreDlorean.FUNCIONES_BOTONES[conf.s_funcion](args);
      }
    } catch {
      console.error(`LA FUNCIÓN [${conf.s_funcion}] NO ESTÁ DEFINIDA`);
    }
  };

  return (
    <>
      {coreDlorean.RELOAD_BUTTONS?.sub_menus && (
        <div className={coreDlorean.RELOAD_BUTTONS?.sub_menus.length === 0 ? "hidden" : ""}>
          <Dropdown
            disabled={coreDlorean.CARGANDO_BOTON_GUARDAR}
            menuStyle={{ minWidth: 200, marginTop: 1 }}
            placement="bottom-end"
            renderTitle={
              isMobile ?
                <Button
                  className='rounded-md'
                  size="sm"
                  variant="twoTone"
                  icon={<HiOutlineViewGridAdd />}
                />
                :
                <Button loading={coreDlorean.CARGANDO_BOTON_GUARDAR}>
                  {coreDlorean.CARGANDO_BOTON_GUARDAR ? "Cargando..." : coreDlorean.RELOAD_BUTTONS?.titulo}
                </Button>
            }
          >
            {/*  <Dropdown.Item variant="divider" /> */}
            {coreDlorean.RELOAD_BUTTONS?.sub_menus &&
              coreDlorean.RELOAD_BUTTONS?.sub_menus.map((val, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={val.s_funcion ? (e) => ejecutar_funcion_boton(e, val) : null}
                  eventKey={val.s_nombre}
                >
                  {Icono(val.sk_boton)} {val.s_nombre}
                </Dropdown.Item>
              ))}
          </Dropdown>
        </div>
      )}
    </>
  );
}
