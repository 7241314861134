import { THEME_ENUM } from 'constants/theme.constant'

/**
 * Since some configurations need to be match with specific themes, 
 * we recommend to use the configuration that generated from demo.
 */


export const themeConfig = (() => {
  switch (window.location.hostname) {
    case 'localhost':
      // Configuración para el entorno de desarrollo
      return {
        "themeColor": "gray",
        "direction": "ltr",
        "mode": JSON.parse(localStorage.getItem('darkMode')) || 'light',
        "locale": "en",
        "primaryColorLevel": 800,
        "cardBordered": false,
        "panelExpand": false,
        "controlSize": "md",
        "navMode": "themed", // light, dark, themed
        "layout": {
          "type": "classic",
          "sideNavCollapse": false
        }
      };

    case '192.168.11.144':
      // Configuración para el entorno de pruebas
      return {
        "themeColor": "red",
        "direction": "ltr",
        "mode": JSON.parse(localStorage.getItem('darkMode')) || 'light',
        "locale": "en",
        "primaryColorLevel": 900,
        "cardBordered": false,
        "panelExpand": false,
        "controlSize": "md",
        "navMode": "themed", // light, dark, themed
        "layout": {
          "type": "classic",
          "sideNavCollapse": false
        }
      };

    case 'apexlw.woodward.mx':
      // Configuración para el entorno de producción
      return {
        "themeColor": "sky",
        "direction": "ltr",
        "mode": JSON.parse(localStorage.getItem('darkMode')) || 'light',
        "locale": "en",
        "primaryColorLevel": 900,
        "cardBordered": false,
        "panelExpand": false,
        "controlSize": "md",
        "navMode": "themed", // light, dark, themed
        "layout": {
          "type": "classic",
          "sideNavCollapse": false
        }
      };

    default:
      // Configuración por defecto
      return {
        "themeColor": "sky",
        "direction": "ltr",
        "mode": "light",
        "locale": "en",
        "primaryColorLevel": 900,
        "cardBordered": false,
        "panelExpand": false,
        "controlSize": "md",
        "navMode": "themed", // light, dark, themed
        "layout": {
          "type": "classic",
          "sideNavCollapse": false
        }
      };
  }
})();
