const arkon_environment = {
  ENVIRONMENT: "test",
  API_URL:
    window.location.hostname === "localhost"
      ? window.location.protocol + "//" + window.location.hostname + ":3001"
      : window.location.protocol + "//" + window.location.host,
  WEBSITE_URL: window.location.protocol + "//" + window.location.host,
};

export default arkon_environment;
