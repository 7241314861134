import React, { useRef } from 'react';
 import { Editor } from '@tinymce/tinymce-react';

 const TinyMCE = (props, ref) => {
  
  const {
    value, 
    height = 500 ,
    language = 'es',
    menubar = false,
    onChange = false,
    filesName = 'files',
    form,
    field,
    plugins = [
      "emoticons",
      "lists",
      "link",
      "image",
      "preview",
      "code",
      "fullscreen",
      "media",
      "table",
      "help",
    ],
    toolbar = `
      undo redo | 
      blocks |
      image |
      table |
      bold italic backcolor forecolor| 
      alignleft aligncenter alignright alignjustify |
      bullist numlist outdent indent | 
      removeformat |
      fullscreen help |
      code
    `
  } = props

  const editorRef = useRef(null);
   return (
     <>
       <Editor
         apiKey='txo86q52w7gelqvuxsrk3ky6humwqc92b51rv2w4u6e9ihrp'
         onInit={(evt, editor) => editorRef.current = editor}
         value = {value}
         onEditorChange= {
            (content) => {
              form.setFieldValue(field.name, content);
            }
          }
         init={{
            height:  height ,
            menubar: menubar,
            language: language ,
            plugins: plugins,
            toolbar: toolbar,
            setup: (editor) => {
              editor.on('change',  (val) => {
                form.setFieldValue(field.name, val.level.content);
                // Get all the img elements in the editor content
                const blobsUrls = new Set();
                
                var images = editor.contentDocument.getElementsByTagName('img');
                // Loop through the images and check for blob URLs
                for (var i = 0; i < images.length; i++) {
                  if (images[i].src.startsWith('blob:')) {
                      blobsUrls.add(images[i].src);
                  }
                }
                form.setFieldValue(filesName, blobsUrls);
              });
            }
          }}
       />
     </>
   );
 };

 export default TinyMCE